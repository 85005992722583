<template>
    <div class="flex gaps">
        <div class="box left center">
            <h4 class="mt-10 mb-5 o-050">CUSTOMER</h4>
        </div>
        <div class="box">
            <el-select
                :popper-append-to-body="false"
                :value="customerId"
                placeholder="Select Person"
                @change="$emit('selectCustomer',$event)">
              <el-option
                v-for="item in basketWaiwers"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
    </div>
</template>

<script>
export default {
  name: 'LessonSelectCustomer',

  props: {
    basketWaiwers: {
      type: Array,
      default: function () {
        return []
      }
    },
    customerId: {
      type: String,
      default: function () {
        return ''
      }
    }
  },

  data () {
    return {}
  }
}
</script>
