<template>
    <div class="box event-panel clickable flex column gaps"
        @click="$emit('switchEvent', { id: item._id, name: item.name, cost: item.cost })">
        <div class="box card-base m-5"
          :class="{ 'bg-accent': item._id == eventId}">
            <div class="p-10 flex column">
                <h4 class="box center pb-10">{{item.name}}</h4>
                <div class="flex">
                     <div class="box self-flex-start m-0 fs-13 left"
                        :class="{ 'o-050': item._id !== eventId }">
                            {{item.duration}} {{item.duration === 1 ? 'session' : 'sessions'}}
                    </div>
                    <div class="box self-flex-end m-0 fs-13 right"
                        :class="{ 'o-050': item._id !== eventId }">
                        {{item.cost}} AED
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'LessonPanel',

  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    },
    eventId: {
      type: String,
      default: function () {
        return ''
      }
    }
  },

  data () {
    return {}
  },

  methods: {}
}
</script>
